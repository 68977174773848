
import { defineComponent } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';

export default defineComponent({
  name: 'trainee-reassessment',
  mixins: [apiEndpoint],
  components: {
    Datatable,
  },
  data() {
    return {
      trainee: {
        entity_id: '',
        training_institute_id: '',
        course_info_id: '',
        tranche_id: '',
        batch_info_id: '',
      },
      assessment: [] as any,
      assesmentData: [] as any,
      api_url: '',
      traineeList: [],
      tranches: [],
      associations: [],
      institutes: [],
      courses: [],
      courseInfo: [],
      batches: [],
      assessors: [],
      textAssessment: '',
      loading: false,
      load: false,
      assessmentkey: 0,
      courseInfoData: false,
      showAssessmentList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    //this.assessment.assessment_date =  new Date().toJSON().slice(0,10).replace(/-/g,'-');
    await this.getTranches();
    await this.associationList();
    this.api_url = this.VUE_APP_API_URL;
    this.emitter.on('trainee-reassess-data-updated', async () => {
      await this.getAssessmentList();
    });
  },
  methods: {
    competencyCheck(event, index) {
      this.assessment[index].competency = event.target.value;
    },
    Approve(index, id) {
      this.load = true;
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;
      let formData = new FormData();
      formData.set('trainee_info_id', id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('user_id', user_id);
      formData.set('assessment_title', this.textAssessment);

      let assesementObj = this.assessment[index];

      for (var key in assesementObj) {
        formData.set(key, assesementObj[key]);
      }

      if (formData.get('assessor_info_id')) {
        Swal.fire({
          title: 'Are you sure you want to assess this trainee?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Assessed!',
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post('assessment/save', formData)
              .then((response) => {
                Swal.fire('Success!', response.data.data, 'success').then(
                  () => {
                    this.emitter.emit('trainee-reassess-data-updated', true);
                    this.load = false;
                  }
                );
              })
              .catch(({ response }) => {
                console.log(response);
                this.load = false;
              });
          }
        });
      } else {
        this.load = false;
        Swal.fire({
          title: 'Please check all the required field',
          html: 'Please select assessor.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async getTranches() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.associations = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/list?entity_id=' +
          this.trainee.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseList() {
      await ApiService.get(
        'course/list?entity_id=' +
          this.trainee.entity_id +
          '&tranche=' +
          this.trainee.tranche_id +
          '&institute_info_id=' +
          this.trainee.training_institute_id +
          '&isReassessmentEnabled=1'
      )
        .then((response) => {
          this.courses = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBatchList() {
      await ApiService.get(
        'batch/list?entity_id=' +
          this.trainee.entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&institute_info_id=' +
          this.trainee.training_institute_id
      )
        .then((response) => {
          this.batches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async checkAssessmentEligiblity() {
      this.load = true;
      await ApiService.get(
        'assessment/re_assessment_eligiblity_check?entity_id=' +
          this.trainee.entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&training_institute_id=' +
          this.trainee.training_institute_id +
          '&batch_info_id=' +
          this.trainee.batch_info_id +
          '&tranche_id=' +
          this.trainee.tranche_id
      )
        .then((response) => {
          if (response.data.data) {
            this.getAssessmentList();
          } else {
            this.load = false;
            this.showAssessmentList = false;
            Swal.fire({
              title: 'Error!',
              html: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssessorList() {
      await ApiService.get(
        'entity/assessor/list?entity_info_id=' +
          this.trainee.entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&institute_info_id=' +
          this.trainee.training_institute_id
      )
        .then((response) => {
          this.assessors = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCorseInfo() {
      await ApiService.get('course/show/' + this.trainee.course_info_id)
        .then((response) => {
          this.courseInfo = response.data.data;
          this.textAssessment =
            'Competency Test of ' +
            response.data.data.code +
            ' | ' +
            response.data.data.course_name +
            ' | ' +
            response.data.data.month +
            ' | ' +
            response.data.data.hour +
            ' | ' +
            response.data.data.unit_cost_total;
          console.log(response.data.data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getAssessmentList() {
      this.getCorseInfo();
      this.getAssessorList();
      await ApiService.get(
        'assessment/list?entity_id=' +
          this.trainee.entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&training_institute_id=' +
          this.trainee.training_institute_id +
          '&batch_info_id=' +
          this.trainee.batch_info_id +
          '&tranche_id=' +
          this.trainee.tranche_id
      )
        .then((response) => {
          this.traineeList = response.data.data;
          for (let i = 1; i <= this.traineeList.length; i++) {
            let tempObj = {
              reassessment_date: '',
              assessor_info_id: '',
              competency: '',
            };
            this.assessment.push(tempObj);
          }
          this.showAssessmentList = true;
          this.assessmentkey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
